/* eslint-disable react/no-danger */
import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { IEmbroideryData } from 'graphql/customer/custom-products';
import styles from './style.css';

export const TextEmbroideryInfo: React.FC<{ embroideryData: IEmbroideryData }> = ({ embroideryData }) => {
    const t = usePhraseTranslater();
    return (
        <>
            { embroideryData.emb_text && (
                <div className={styles.textEmbroidery}>
                    <span className={styles.embroideryInfoTitle}>{t('Text Embroidery')}</span>
                    <div
                        className={styles.textEmbroideryInfo}
                        dangerouslySetInnerHTML={{ __html: embroideryData.emb_text }}
                    />
                </div>
            )}
        </>
    );
};
