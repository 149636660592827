/* eslint-disable camelcase */
import { gql, TypedDocumentNode } from '@apollo/client';
import { CART_FIELDS } from './fragments';

interface IEavOptionsInput {
    option_id: string;
    option_value: string;
}

export interface IAddToCartItemInput {
    item_id: number;
    type: string;
    options: string;
    qty: number;
    selections: IEavOptionsInput[];
    lpImages?: string[];
}

export interface IAddToCartInput {
    cart_id: string;
    items: IAddToCartItemInput[];
}

export const ADD_ITEMS_TO_CART: TypedDocumentNode = gql`
    ${CART_FIELDS}
    mutation ADD_ITEMS_TO_CART($addToCart: AddItemsToCartInput!) {
        addItemsToCart(input: $addToCart) {
            cart {
                ...CartFields
            }
        }
    }
`;
