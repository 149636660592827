import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { IMyCustomProduct } from 'graphql/customer/custom-products';
import {
    TextEmbroideryInfo,
    LogoEmbroideryInfo,
    FlagEmbroideryInfo,
    SpecialInstructionsEmbroideryInfo,
} from './embroidery-info';
import { AddToCart } from '../add-to-cart';
import styles from './style.css';

interface ICustomProductInfoProps {
    product: IMyCustomProduct;
}

export const CustomProductInfo: React.FC<ICustomProductInfoProps> = ({ product }) => {
    const t = usePhraseTranslater();
    return (
        <>
            <div className={styles.productInfo}>
                <h1 className={styles.productName}>{product.product_name}</h1>
                <span className={styles.productDetail}>
                    {t('Description')}
                    :&nbsp;
                    {product.product_description}
                </span>
                <span className={styles.productDetail}>
                    {t('Color')}
                    :&nbsp;
                    {product.embroidery_data.emb_color}
                </span>
                <TextEmbroideryInfo embroideryData={product.embroidery_data} />
                <LogoEmbroideryInfo embroideryData={product.embroidery_data} />
                <FlagEmbroideryInfo embroideryData={product.embroidery_data} />
                <SpecialInstructionsEmbroideryInfo embroideryData={product.embroidery_data} />
                <AddToCart product={product} />
            </div>
        </>
    );
};
