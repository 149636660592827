import React from 'react';
import { IAddToCartItem } from '../../add-to-cart-state';
import styles from './style.css';

interface IEmbroideryTextProps {
    item: IAddToCartItem;
    handleItemChange: (item: IAddToCartItem) => void;
}

export const EmbroideredText: React.FC<IEmbroideryTextProps> = ({ item, handleItemChange }) => {
    const handleEmbroideryTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedItem = {
            ...item,
            emb_text_key_value: item.emb_text_key_value.map((keyValue) => {
                if (keyValue.option_code === event.target.name) {
                    return {
                        ...keyValue,
                        option_value: event.target.value,
                    };
                }
                return keyValue;
            }),
        };
        handleItemChange(updatedItem);
    };
    return (
        <div className={styles.addToCartItemEmbroideryText}>
            <div className={styles.addToCartItemEmbroideryTextLine}>
                <label className={styles.addToCartItemEmbroideryTextLineLabel} htmlFor="embroidery_line1">
                    <div className={styles.lineTitle}>Text Line 1</div>
                    <input
                        className={styles.embroideryLineInput}
                        type="text"
                        name="embroidery_line1"
                        value={item.emb_text_key_value.filter(keyValue => keyValue.option_code === 'embroidery_line1')[0]?.option_value}
                        onChange={handleEmbroideryTextChange}
                    />
                </label>
            </div>
            <div className={styles.addToCartItemEmbroideryTextLine}>
                <label className={styles.addToCartItemEmbroideryTextLineLabel} htmlFor="embroidery_line2">
                    <div className={styles.lineTitle}>Text Line 2</div>
                    <input
                        className={styles.embroideryLineInput}
                        type="text"
                        name="embroidery_line2"
                        value={item.emb_text_key_value.filter(keyValue => keyValue.option_code === 'embroidery_line2')[0]?.option_value}
                        onChange={handleEmbroideryTextChange}
                    />
                </label>
            </div>
            <div className={styles.addToCartItemEmbroideryTextLine}>
                <label className={styles.addToCartItemEmbroideryTextLineLabel} htmlFor="embroidery_line3">
                    <div className={styles.lineTitle}>Text Line 3</div>
                    <input
                        className={styles.embroideryLineInput}
                        type="text"
                        name="embroidery_line3"
                        value={item.emb_text_key_value.filter(keyValue => keyValue.option_code === 'embroidery_line3')[0]?.option_value}
                        onChange={handleEmbroideryTextChange}
                    />
                </label>
            </div>
        </div>
    );
};
