import React from 'react';
import { IMyCustomProduct } from 'graphql/customer/custom-products';
import { CustomProductImages } from './custom-product-images';
import { CustomProductInfo } from './custom-product-info';
import styles from './style.css';

interface ICustomProductDetails {
    product: IMyCustomProduct;
}

export const CustomProductDetails: React.FC<ICustomProductDetails> = ({ product }) => (
    <div className={styles.myProduct}>
        <div className={styles.productContent}>
            <CustomProductImages product={product} />
            <CustomProductInfo product={product} />
        </div>
    </div>
);
