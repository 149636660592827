import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { IMyCustomProduct } from 'graphql/customer/custom-products';
import { Carousel } from 'ui/component/product-configurator/media/carousel/carousel';
import configuratorMediaStyles from 'ui/component/product-configurator/media/styles.css';
import { convertImagesForCarousel } from './convert-images-for-carousel';
import styles from './style.css';

interface ICustomProductImagesProps {
    product: IMyCustomProduct;
}

export const CustomProductImages: React.FC<ICustomProductImagesProps> = ({ product }) => {
    const carouselImages = convertImagesForCarousel(product);
    const carouselProduct = {
        ...product,
        attributes: [],
    };
    return (
        <div className={styles.productImagesContainer}>
            <div className={classes(configuratorMediaStyles.imageCarouselWrap, styles.productImages)}>
                <Carousel product={carouselProduct} images={carouselImages} />
            </div>
        </div>
    );
};
