import React, { useState } from 'react';
import { IAddToCartItem } from '../../add-to-cart-state';
import { SingleSize } from './single-size';
import { MultiSize } from './multi-size';
import styles from './style.css';

interface ISizesProps {
    item: IAddToCartItem;
    handleItemChange: (item: IAddToCartItem) => void;
}

export const Sizes: React.FC<ISizesProps> = ({ item, handleItemChange }) => {
    const [singleOrMultiSize, setSingleOrMultiSize] = useState('single');
    const handleSingleOrMultiSize = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSingleOrMultiSize(event.target.value);
    };
    return (
        <div className={styles.addToCartItemSizes}>
            <div className={styles.addToCartItemSizesTabs}>
                <div className={`${styles.addToCartItemSizesTab} ${singleOrMultiSize === 'single' ? styles.active : ''}`}>
                    <label>
                        <input
                            type="radio"
                            name="singleOrMultiSize"
                            value="single"
                            checked={singleOrMultiSize === 'single'}
                            onChange={handleSingleOrMultiSize}
                        />
                        Select Size
                    </label>
                </div>
                <div className={`${styles.addToCartItemSizesTab} ${singleOrMultiSize === 'multi' ? styles.active : ''}`}>
                    <label>
                        <input
                            type="radio"
                            name="singleOrMultiSize"
                            value="multi"
                            checked={singleOrMultiSize === 'multi'}
                            onChange={handleSingleOrMultiSize}
                        />
                        Order Multiple
                    </label>
                </div>
            </div>
            <div className={styles.addToCartItemSizesContent}>
                { singleOrMultiSize === 'multi' ? (
                    <MultiSize item={item} handleItemChange={handleItemChange} />
                ) : (
                    <SingleSize item={item} handleItemChange={handleItemChange} />
                )}
            </div>
        </div>
    );
};
