import React, { useEffect } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePriceFormatter, usePriceRounder } from '@silkpwa/module/ecommerce-catalog/config';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { IAddToCartItem } from 'ui/component/custom-product/custom-product-details/add-to-cart/add-to-cart-state';
import { sortSizes } from '../sort-sizes';
import sizeStyles from '../style.css';
import styles from './style.css';

interface ISingleSizeProps {
    item: IAddToCartItem;
    handleItemChange: (item: IAddToCartItem) => void;
}

export const SingleSize: React.FC<ISingleSizeProps> = ({ item, handleItemChange }) => {
    const availableSizes = sortSizes(item.emb_color_size.size);
    const savedSize = availableSizes.find(size => size.title === item.emb_size);
    const [qty, setQty] = React.useState<number>(1);
    const [selectedSize, setSelectedSize] = React.useState<number>(savedSize && savedSize.available ? savedSize.id : 0);
    const lowestPrice = Math.min(...availableSizes.map(size => size.price));
    const formatPrice = usePriceFormatter();
    const roundPrice = usePriceRounder();
    const handleSizeClick = (sizeId: number) => {
        setSelectedSize(sizeId);
    };
    const handleKeyDown = (
        event: React.KeyboardEvent<HTMLDivElement>,
        sizeId: number,
    ) => {
        if (event.key === 'Enter' || event.key === ' ') {
            handleSizeClick(sizeId);
        }
    };
    useEffect(() => {
        const selectedSizeObject =
            availableSizes.find(size => size.id === selectedSize);
        handleItemChange({
            ...item,
            size_qty: [
                {
                    id: selectedSize,
                    qty,
                    price: selectedSizeObject?.price ?? item.emb_size_price,
                },
            ],
        });
    }, [qty, selectedSize]);
    const symbol = '+';
    return (
        <>
            <div className={styles.addToCartItemSize}>
                {availableSizes.map(size => (
                    <div
                        role="button"
                        tabIndex={0}
                        key={size.id}
                        className={
                            classes(
                                styles.sizeTitle,
                                {
                                    [styles.active]: selectedSize === size.id,
                                    [styles.disabled]: !size.available,
                                },
                            )
                        }
                        onClick={() => size.available && handleSizeClick(size.id)}
                        onKeyDown={event => handleKeyDown(event, size.id)}
                    >
                        {size.price > lowestPrice && (
                            <span className={sizeStyles.upcharge}>
                                {symbol}
                                {formatPrice(roundPrice(size.price - lowestPrice))}
                            </span>
                        )}
                        {size.title}
                    </div>
                ))}
            </div>
            <div className={styles.sizeQtyContainer}>
                <span className={styles.qtyLabel}>Quantity</span>
                <div className={styles.sizeQty}>
                    <AccessibleButton tag="button" action={() => setQty(qty - 1)}>
                        -
                    </AccessibleButton>
                    <input
                        type="number"
                        name="qty"
                        value={qty}
                        onBlur={e => setQty(parseInt(e.target.value, 10))}
                    />
                    <AccessibleButton tag="button" action={() => setQty(qty + 1)}>
                        +
                    </AccessibleButton>
                </div>
            </div>
        </>
    );
};
