import React, { useEffect } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { classes } from '@silkpwa/module/util/classes';
import { StandardLoadingImage } from 'ui/component/loading-image';
import { usePriceFormatter } from '@silkpwa/module/ecommerce-catalog/config';
import { IMyCustomProduct } from 'graphql/customer/custom-products';
import { ADD_ITEMS_TO_CART } from 'graphql/cart/add-to-cart';
import {
    addCustomProductToCartItems,
    addToCartItemsVar,
    removeCustomProductFromCartItems,
    addToCartSubtotalVar,
    prepareAddToCart,
} from './add-to-cart-state';
import { AddToCartItem } from './add-to-cart-item';
import { CustomProductOptions } from './custom-product-options';
import styles from './style.css';

interface IAddToCartProps {
    product: IMyCustomProduct;
}

export const AddToCart: React.FC<IAddToCartProps> = ({ product }) => {
    const addToCartItems = useReactiveVar(addToCartItemsVar);
    const subTotal = useReactiveVar(addToCartSubtotalVar);
    const [error, setError] = React.useState('');
    const formatPrice = usePriceFormatter();
    const [addingToCart, setAddingToCart] = React.useState(false);
    const [addToCart] = useMutation(ADD_ITEMS_TO_CART, {
        onCompleted: () => {
            window.location.href = `${process.env.MAGENTO_API}/checkout/cart`;
        },
        onError: (error) => {
            setError(error.message.toString());
            setAddingToCart(false);
        },
    });
    useEffect(() => {
        addCustomProductToCartItems(product);
    }, []);
    const handleAddItem = () => {
        addCustomProductToCartItems(product);
    };
    const handleRemoveItem = (index: number) => {
        removeCustomProductFromCartItems(index);
    };
    const handleAddToCart = async () => {
        setAddingToCart(true);
        await addToCart({ variables: { addToCart: prepareAddToCart() } });
    };
    return (
        <div className={styles.addToCart}>
            { addToCartItems.items.map((item, index) => (
                <AddToCartItem
                    key={item.id}
                    item={item}
                    itemKey={index}
                    handleRemove={() => handleRemoveItem(item.id)}
                />
            )) }
            <div className={styles.addItemContainer}>
                <button type="button" className={styles.addItemButton} onClick={handleAddItem}>Add Item</button>
            </div>
            { product.embroidery_data.emb_digitization_price > 0 && (
                <div className={styles.digitization}>
                    Logo Digitization:&nbsp;
                    {formatPrice(product.embroidery_data.emb_digitization_price)}
                </div>
            )}
            <div className={styles.subtotal}>
                Subtotal:&nbsp;
                {formatPrice(subTotal)}
            </div>
            <div className={styles.addToCartButtonContainer}>
                <div className={styles.error}>{error}</div>
                { addingToCart && <div className={styles.loading}><StandardLoadingImage /></div> }
                <button
                    type="button"
                    className={classes(styles.addToCartButton, { [styles.disabled]: addingToCart })}
                    onClick={handleAddToCart}
                    disabled={addingToCart}
                >
                    Add to Cart
                </button>
                <CustomProductOptions product={product} />
            </div>
        </div>
    );
};
