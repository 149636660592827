import React, { useEffect, useState } from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { usePriceFormatter, usePriceRounder } from '@silkpwa/module/ecommerce-catalog/config';
import { IAddToCartItem } from 'ui/component/custom-product/custom-product-details/add-to-cart/add-to-cart-state';
import { sortSizes } from '../sort-sizes';
import sizeStyles from '../style.css';
import styles from './style.css';

interface IMultiSizeProps {
    item: IAddToCartItem;
    handleItemChange: (item: IAddToCartItem) => void;
}

export const MultiSize: React.FC<IMultiSizeProps> = ({ item, handleItemChange }) => {
    const formatPrice = usePriceFormatter();
    const roundPrice = usePriceRounder();
    const availableSizes = sortSizes(item.emb_color_size.size);
    const lowestPrice = Math.min(...availableSizes.map(size => size.price));
    const [orderQuantities, setOrderQuantities] = useState<{ [key: number]: number }>(
        availableSizes.reduce((acc, size) => {
            acc[size.id] = size.title === item.emb_size ? 1 : 0;
            return acc;
        }, {} as { [key: number]: number }),
    );
    const handleQtyChange = (sizeId: number, delta: number) => {
        setOrderQuantities(prevQuantities => ({
            ...prevQuantities,
            [sizeId]: Math.max(0, (prevQuantities[sizeId] || 0) + delta),
        }));
    };
    const handleInputChange = (sizeId: number, value: string) => {
        const qty = Math.max(0, parseInt(value, 10) || 0);
        setOrderQuantities(prevQuantities => ({
            ...prevQuantities,
            [sizeId]: qty,
        }));
    };
    useEffect(() => {
        const sizeQtyArray = Object.keys(orderQuantities).map((key) => {
            const size = availableSizes.find(size => size.id === Number(key));
            return {
                id: Number(key),
                qty: orderQuantities[Number(key)],
                price: orderQuantities[Number(key)] && size?.price ? size.price : 0,
            };
        });
        handleItemChange({
            ...item,
            size_qty: sizeQtyArray,
        });
    }, [orderQuantities]);
    const symbol = '+';
    return (
        <div className={styles.addToCartItemSizes}>
            {availableSizes.map((size) => {
                const itemEmbTextPrice = item.emb_text_price * item.emb_text_key_value.filter(
                    text => text.option_value &&
                    ['embroidery_line1', 'embroidery_line2', 'embroidery_line3'].includes(text.option_code),
                ).length;
                const itemEmbroideryTotal =
                    (
                        itemEmbTextPrice +
                        item.emb_flag_price +
                        item.emb_logo_price
                    );
                const itemSubtotal =
                    orderQuantities[size.id] > 0
                        ? (orderQuantities[size.id] *
                            (size.price + itemEmbroideryTotal)) : 0;
                return (
                    <div key={size.id} className={styles.addToCartItemSize}>
                        <div className={styles.sizeTitle}>
                            {size.price > lowestPrice && (
                                <span className={sizeStyles.upcharge}>
                                    {symbol}
                                    {formatPrice(roundPrice(size.price - lowestPrice))}
                                </span>
                            )}
                            {size.title}
                        </div>
                        <div className={styles.sizeQty}>
                            <AccessibleButton tag="button" action={() => handleQtyChange(size.id, -1)}>
                                -
                            </AccessibleButton>
                            <input
                                type="number"
                                name={`size_qty_${size.id}`}
                                value={orderQuantities[size.id] || 0}
                                onChange={e => handleInputChange(size.id, e.target.value)}
                            />
                            <AccessibleButton tag="button" action={() => handleQtyChange(size.id, 1)}>
                                +
                            </AccessibleButton>
                        </div>
                        <div className={styles.sizeSubtotal}>
                            {itemSubtotal > 0 &&
                                formatPrice(itemSubtotal)}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
