import React from 'react';
import { IMyCustomProduct } from 'graphql/customer/custom-products';
import styles from './style.css';

interface ICustomProductOptionsProps {
    product: IMyCustomProduct;
}

export const CustomProductOptions: React.FC<ICustomProductOptionsProps> = ({ product }) => (
    <div className={styles.customProductOptions}>
        <div className={styles.downloadLink}>
            <a
                href={`${process.env.MAGENTO_API}/myproducts/index/download/id/${product.entity_id}`}
                target="_self"
                rel="noopener noreferrer"
            >
                <i className="fa-solid fa-download" />
                &nbsp;Download
            </a>
        </div>
        <div className={styles.deleteLink}>
            <a
                href={`${process.env.MAGENTO_API}/myproducts/index/delete/id/${product.entity_id}`}
                target="_self"
                rel="noopener noreferrer"
            >
                <i className="fa-solid fa-trash" />
                &nbsp;Delete
            </a>
        </div>
    </div>
);
