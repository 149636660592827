import React, { useState } from 'react';
import { toInteger } from 'lodash';
import { Modal } from 'ui/component/modal';
import { IAddToCartItem, updateCustomProductInCartItems } from '../add-to-cart-state';
import { EmbroideredText } from './embroidered-text';
import { Sizes } from './sizes';
import styles from './style.css';

interface IAddToCartItemProps {
    item: IAddToCartItem;
    itemKey: number;
    handleRemove: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const AddToCartItem: React.FC<IAddToCartItemProps> = ({ item, itemKey, handleRemove }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleItemChange = (item: IAddToCartItem) => {
        const updatedItem = {
            ...item,
        };
        updateCustomProductInCartItems(updatedItem, itemKey);
    };
    const openModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const confirmRemove = (event: React.MouseEvent<HTMLButtonElement>) => {
        handleRemove(event);
        closeModal();
    };
    return (
        <div className={styles.addToCartItem}>
            <div className={styles.addToCartItemInfo}>
                <div className={styles.addToCartItemTitle}>
                    Item:&nbsp;
                    {toInteger(itemKey) + 1}
                </div>
                {toInteger(itemKey) > 0 && (
                    <div className={styles.removeItem}>
                        <button
                            type="button"
                            className={styles.removeItemButton}
                            onClick={openModal}
                        >
                            <i className="fa-solid fa-trash" />
                        </button>
                    </div>
                )}
            </div>
            <div className={styles.addToCartItemOptions}>
                <EmbroideredText item={item} handleItemChange={handleItemChange} />
                { item?.emb_color_size?.size?.length > 0 && (
                    <Sizes item={item} handleItemChange={handleItemChange} />
                )}
            </div>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <h3>Confirm Remove Item</h3>
                <p>Are you sure you want to remove this item?</p>
                <div className={styles.buttonContainer}>
                    <button type="button" className={styles.modalButtonYes} onClick={confirmRemove}>Yes</button>
                    <button type="button" className={styles.modalButtonNo} onClick={closeModal}>No</button>
                </div>
            </Modal>
        </div>
    );
};
