import { IColorSize } from '../../add-to-cart-state';

const sizeOrder = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', '2XL', '3XL', '4XL', '5XL', '6XL', '7XL', '8XL'];

export const sortSizes = (sizes: IColorSize[]): IColorSize[] => sizes.sort((a, b) => {
    const indexA = sizeOrder.indexOf(a.title);
    const indexB = sizeOrder.indexOf(b.title);
    const isNumericA = !Number.isNaN(Number(a.title));
    const isNumericB = !Number.isNaN(Number(b.title));
    if (isNumericA && isNumericB) {
        return Number(a.title) - Number(b.title);
    }
    if (isNumericA) return 1;
    if (isNumericB) return -1;
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;
    return indexA - indexB;
});
