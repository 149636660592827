import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { StandardLoadingImage } from 'ui/component/loading-image';
import { DocumentTitle } from '@silkpwa/module/react-component/document-title';
import { Breadcrumbs } from 'ui/component/breadcrumbs';
import { useQuery } from '@apollo/client';
import { getPrettyParamFromUrl } from 'ui/util/get-query-param-by-name';
import { MyCustomProductsLayout } from 'ui/page/customer-pages/layouts/my-custom-products-layout';
import { CustomProductDetails } from 'ui/component/custom-product/custom-product-details';
import { GET_MY_CUSTOM_PRODUCT } from 'graphql/customer/custom-products';
import styles from './style.css';

const MyCustomProductDetailsPage: React.FC = () => {
    const t = usePhraseTranslater();
    const myProductId = getPrettyParamFromUrl('id');
    const { data } = useQuery(GET_MY_CUSTOM_PRODUCT, { variables: { id: myProductId } });
    const breadcrumbs = [
        {
            label: t('My Custom Products'),
            url: `${process.env.MAGENTO_API}/myproducts/index/index`,
        },
        {
            label: data?.getMyProduct?.product_name || t('Product Details'),
            url: '',
        },
    ];
    return (
        <div data-page-type="custom-product-details">
            <DocumentTitle>
                {`${data?.getMyProduct?.product_name} - ${t('My Custom Products')}`}
            </DocumentTitle>
            <Breadcrumbs path={breadcrumbs} />
            <div className={styles.myCustomProductDetailsPage}>
                <MyCustomProductsLayout>
                    { data?.getMyProduct ? (
                        <CustomProductDetails product={data?.getMyProduct} />
                    ) : (
                        <div><StandardLoadingImage /></div>
                    )}
                </MyCustomProductsLayout>
            </div>
        </div>
    );
};

export { MyCustomProductDetailsPage as default };
