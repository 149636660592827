import { IMyCustomProduct } from 'graphql/customer/custom-products';
import { IProductImage } from '@silkpwa/magento/api/util/product';

export const convertImagesForCarousel = (
    product: IMyCustomProduct,
): IProductImage[] => product.embroidery_data.emb_image.map((image, index) => ({
    type: 'image',
    product_id: product.item_id.toString(),
    image: image.img,
    thumb: image.thumb,
    full: image.large,
    is_thumbnail: index === 0 ? 1 : 0,
}));
