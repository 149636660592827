/* eslint-disable camelcase */
import { makeVar } from '@apollo/client';
import { getSessionStorageData } from 'ui/util/session-storage';
import { IMyCustomProduct } from 'graphql/customer/custom-products';
import { IAddToCartInput } from 'graphql/cart/add-to-cart';
import { toInteger } from 'lodash';

interface IEavOptions {
    option_id: string;
    option_value: string;
}

export interface IColorSize {
    available: boolean;
    id: number;
    price: number;
    sku: string;
    title: string;
}

interface IEmbColorSize {
    color: IColorSize[];
    size: IColorSize[];
}

interface IEmbKeyTextValue {
    option_code: string;
    option_id: string;
    option_value: string;
}

export interface IAddToCartItem {
    id: number;
    item_id: number;
    type: string;
    options: string;
    qty: number;
    selections: IEavOptions[];
    lpImages?: string[];
    emb_size: string;
    emb_color_size: IEmbColorSize;
    size_qty: {
        id: number;
        qty: number;
        price: number;
    }[];
    emb_text_key_value: IEmbKeyTextValue[];
    emb_text_price: number;
    emb_digitization_price: number;
    emb_flag_price: number;
    emb_logo_price: number;
    emb_size_price: number;
    emb_color_price: number;
}

export interface IAddToCart {
    cart_id: string;
    items: IAddToCartItem[];
}

interface ISizeQty {
    id: number;
    qty: number;
    price: number;
}

const initializeAddToCart = (): IAddToCart => ({
    cart_id: getSessionStorageData('cartId', true) as string,
    items: [],
});

export const addToCartItemsVar = makeVar<IAddToCart>(initializeAddToCart());

export const addToCartSubtotalVar = makeVar<number>(0);

const updateSubtotal = () => {
    const { items } = addToCartItemsVar();
    const subtotal = items.reduce((acc, item) => {
        const embTextPrice = item.emb_text_price * item.emb_text_key_value.filter(
            text => text.option_value &&
            ['embroidery_line1', 'embroidery_line2', 'embroidery_line3'].includes(text.option_code),
        ).length;
        const totalQty = item.size_qty.length > 0 ? item.size_qty.reduce((acc, size) => acc + size.qty, 0) : item.qty;
        const sizePriceTotal =
            item.size_qty.length > 0
                ? item.size_qty.reduce((acc, size) => acc + size.price, 0)
                : item.emb_color_price;
        const embroideryTotal =
            (
                embTextPrice +
                item.emb_flag_price +
                item.emb_logo_price
            ) * totalQty;
        return acc + item.emb_digitization_price + sizePriceTotal + embroideryTotal;
    }, 0);
    addToCartSubtotalVar(subtotal);
};

const initializeSizeQty =
    (sizes: IColorSize[]): ISizeQty[] => sizes.map(
        size => ({ id: size.id, qty: 0, price: size.price }),
    );

export const addCustomProductToCartItems = (
    product: IMyCustomProduct,
) => {
    const item = {
        id: addToCartItemsVar().items.length - 1,
        item_id: product.embroidery_data.original_product_id,
        type: 'embroidery',
        qty: 1,
        selections: product.embroidery_data.emb_selections,
        options: product.embroidery_data.emb_options,
        emb_size: product.embroidery_data.emb_size,
        emb_color_size: product.embroidery_data.emb_color_size,
        emb_text_key_value: product.embroidery_data.emb_text_key_value,
        size_qty: initializeSizeQty(product.embroidery_data.emb_color_size.size ?? []),
        emb_text_price: product.embroidery_data.emb_text_price,
        emb_digitization_price: product.embroidery_data.emb_digitization_price,
        emb_flag_price: product.embroidery_data.emb_flag_price,
        emb_logo_price: product.embroidery_data.emb_logo_price,
        emb_size_price: product.embroidery_data.emb_size_price,
        emb_color_price: product.embroidery_data.emb_color_price,
    };
    addToCartItemsVar({
        ...addToCartItemsVar(),
        items: [...addToCartItemsVar().items, item],
    });
    updateSubtotal();
};

export const updateCustomProductInCartItems = (
    item: IAddToCartItem,
    index: number,
) => {
    const { items } = addToCartItemsVar();
    items[index] = item;
    addToCartItemsVar({
        ...addToCartItemsVar(),
        items,
    });
    updateSubtotal();
};

export const removeCustomProductFromCartItems = (
    index: number,
) => {
    const { items } = addToCartItemsVar();
    items.splice(index, 1);
    addToCartItemsVar({
        ...addToCartItemsVar(),
        items,
    });
    updateSubtotal();
};

interface IParentOptions {
    super_attribute: { [key: string]: number };
}

interface IEmbroideryOptions {
    id: number;
    options: any;
    qty: number;
    parent_options: IParentOptions;
}

const updateTextLines = (options: string[], embTextKeyValue: IEmbKeyTextValue[]) => {
    const updatedOptions = options;
    embTextKeyValue.forEach(({ option_id, option_code, option_value }) => {
        const index = toInteger(option_id);
        if (['embroidery_line1', 'embroidery_line2', 'embroidery_line3'].includes(option_code)) {
            updatedOptions[index] = option_value;
        }
    });
    return updatedOptions;
};

const prepareEmbroideryOptions = (
    item: IAddToCartItem,
    size: ISizeQty|null,
): string => {
    const optionsObject: IEmbroideryOptions[] = JSON.parse(item.options) as Array<IEmbroideryOptions>;
    const updatedOptionsObject = optionsObject.map((option) => {
        const updatedOption = option;
        if (option.options) {
            updatedOption.options = updateTextLines(option.options, item.emb_text_key_value);
        }
        if (size && option?.parent_options?.super_attribute) {
            updatedOption.parent_options.super_attribute[187] = size.id; // TODO don't hardcode this id
        }
        return updatedOption;
    });
    return JSON.stringify(updatedOptionsObject);
};

const updateSelections = (
    selections: IEavOptions[],
    size: ISizeQty|null,
) => selections.map((selection) => {
    if (size && selection.option_id === '187') { // TODO don't hardcode this id
        return { option_id: selection.option_id, option_value: size.id.toString() };
    }
    return { option_id: selection.option_id, option_value: selection.option_value }; // Filters out typename
});

export const prepareAddToCart = (): IAddToCartInput => {
    const items = addToCartItemsVar().items.flatMap((item) => {
        if (item.size_qty.length > 0) {
            return item.size_qty.filter(size => size.qty > 0).map(size => ({
                item_id: item.item_id,
                type: item.type,
                options: prepareEmbroideryOptions(item, size),
                qty: size.qty,
                selections: updateSelections(item.selections, size),
                lpImages: item.lpImages,
            }));
        }
        return [{
            item_id: item.item_id,
            type: item.type,
            options: prepareEmbroideryOptions(item, null),
            qty: item.qty,
            selections: updateSelections(item.selections, null),
            lpImages: item.lpImages,
        }];
    });
    return {
        cart_id: addToCartItemsVar().cart_id,
        items,
    };
};
